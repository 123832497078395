
import { ref, watch } from 'vue';
import CardTable from '@/components/kt-datatable/CardTable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ApiService from '@/core/services/ApiService';
import { hideModal } from '@/core/helpers/dom';
import { reinitializeComponents } from '@/core/plugins/keenthemes';

export default {
  name: 'ManageModel',
  components: {
    CardTable,
  },
  setup() {
    const breadcrumbs = ['Car & Function', ['Manage Car Model']];
    const tableHeader = [
      {
        key: 'no',
        name: 'No.',
        sortable: true,
      },
      {
        name: 'Model',
        key: 'name',
        sortable: true,
      },
      {
        name: 'Type',
        key: 'type',
        sortable: true,
      },
      {
        name: 'Brand',
        key: 'brand',
        sortable: true,
      },
      {
        name: 'Actions',
        key: 'actions',
        sortable: false,
      },
    ];

    const currentIndex = ref(-1);
    const tableData = ref([]);
    const loading = ref(true);
    const types = ref([]);
    const brands = ref([]);
    const modalRef = ref(null);
    const inputRef = ref({
      name: '',
      brand: '',
      type: '',
    });
    const tableRef = ref<any>(null);
    const filterData = ref<any>([]);

    watch(
      () => [tableRef.value?.rowsPerPage, tableRef.value?.currentPage],
      async ([rows, page]) => {
        if (!brands.value.length) {
          const brandData = await ApiService.get(
            'car-brand?page=1&pageSize=1000000'
          );
          brands.value = brandData.data.datas;
          const brandsCopy: any = [...brands.value];
          brandsCopy.unshift({ name: 'Brand', key: 'brand' });

          filterData.value.push(brandsCopy);
        }

        if (!types.value.length) {
          const typeData = await ApiService.get(
            'car-type?page=1&pageSize=1000000'
          );
          types.value = typeData.data.datas;
          const typesCopy: any = [...types.value];
          typesCopy.unshift({ name: 'Type', key: 'type' });

          filterData.value.push(typesCopy);
        }

        const modelData = await ApiService.get(
          `car-brand-model?page=${page}&pageSize=${rows}`
        );
        tableData.value = modelData.data.datas;
        tableRef.value.total = modelData.data.total;

        reinitializeComponents();
        loading.value = false;
      }
    );

    const create = async () => {
      await ApiService.post('car-brand-model', {
        name: inputRef.value.name,
        type: inputRef.value.type,
        brand: inputRef.value.brand,
      });

      hideModal(modalRef.value);

      Swal.fire({
        text: 'New Model has been created',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Return to Model Lists',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });

      inputRef.value = {
        name: '',
        brand: '',
        type: '',
      };

      await tableRef.value.getData(
        tableRef.value.currentPage,
        tableRef.value.rowsPerPage
      );

      reinitializeComponents();
    };

    return {
      tableHeader,
      breadcrumbs,
      currentIndex,
      types,
      brands,
      tableData,
      loading,
      modalRef,
      inputRef,
      create,
      tableRef,
      filterData,
    };
  },
};
